.essential-theme.essential-theme--awesome {

  ion-list, ion-item-group {

    ion-item[color] {
      --detail-icon-color: var(--ion-color-contrast);
      --detail-icon-opacity: 1;
      ion-icon {
        color: var(--ion-color-contrast);
      }
    }

  }
  .theme-light ion-list, .theme-light ion-item-group {
     ion-item-divider:not([color]) {
      background-color: var(--ion-color-is-mute-light-tint);
      color: var(--ion-color-is-mute-light-contrast);
    }
  }
}
