// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//@import "./theme/essential/theme";
@import "./theme/awesome/theme";

h1{
	padding: 0.4em !important;
	font-size: 1.4em !important;
	margin: 0.4em;
	font-weight: 900;
	background-color: #DDDDDD;
	// border:1px solid red;
}
//////////////////////////////////////
//////////////////////////////////////
//////////////////////////////////////
//////////////////////////////////////
//////////////////////////////////////
//////////////////////////////////////
.qrcode2{
	background-color: #FFFFFF;
	width: 2em;
	height: 2em;
	-webkit-mask-size: 2em;
	mask-size: 2em;
}

#divVideoElement{
	z-index: 999999;
	width: 100vw;
	height: 100vh;
	position: absolute;
	left: 0;
	top: 0;
    background: rgba(0,0,0,0.9);
}
#divVideoElement:after{
	content:'';
	width: 60vw;
	height: 60vw;
	border: 4px solid red;
	border-radius: 1em;
	position: absolute;
    left: 50vw;
    top: 50vh;
    margin-left: -30vw;
    margin-top: -30vw;
}
#videoElement{
	width: 100%;
	height: 100%;
}
#btnannulerqrscan{
	position: absolute;
	z-index: 9999999;
	width: 100px;
	height: 100px;
	right: 1em;
	border-radius: 999px;
	// border:1px solid #FFAAAA;
	top: 1em;
	opacity: 0.5;
	padding: 0.5em;
	background-color: #FFFFFF;
	background-image: url('assets/window-close.svg');
	background-size: 150px 150px;
	background-repeat: no-repeat;
	background-position: center center;
}
#btnswitchqrscan{
	position: absolute;
	z-index: 9999999;
	width: 100px;
	height: 100px;
	left: 1em;
	border-radius: 999px;
	top: 1em;
	opacity: 0.5;
	padding: 0.5em;
	background-color: #FFFFFF;
	background-image: url('assets/sync-alt.svg');
	background-size: 60% 60%;
	background-repeat: no-repeat;
	background-position: center center;
}
#divlistecamqrscan{
	display: none;
	width: 100%;
    text-align: center;
    position: absolute;
    top: calc( 2em + 100px );
    margin: auto;
}
.btnchangercamqrscan{
	width: 90%;
	margin: 0.5em auto;
	border-radius: 5px;
	padding: 0.5em;
	background-color: #FFFFFF;
}

.badge_probleme
{
	margin-right:0.3em !important;
	font-size:0.5em;
}
// informatique
.badge_probleme_1 { --background:#4444AA !important; }
// entretien
.badge_probleme_2 { --background:#44AA44 !important; }
// clim
.badge_probleme_3 { --background:#99FFFF !important; --color:#000000; border:1px solid #000000; }
// elec
.badge_probleme_4 { --background:#FFCE00 !important; --color:#000000; border:1px solid #000000; }
// autre
.badge_probleme_14 { --background:#000000 !important; }




.popover__wrapper {
  position: relative;
  // display: inline-block;
  margin: 0;
  padding: 0;
}
.popover__content {
  opacity: 0.9;
  visibility: hidden;
  position: absolute;
  // left: -150px;
  transform: translate(0, 10px);
  background-color: #000000;
  color: #FFFFFF;
  padding: 1.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
  border-radius:1em;
}
.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover__message { text-align: center; }
