.essential-theme.essential-theme--awesome {
  ion-button {
    --border-radius: 64px;
    --box-shadow: none;
    height: 2.5em;
    text-transform: none;
    font-weight: 400;
    letter-spacing: 0;
  }
  p {
  	color: var(--ion-color-medium);
  }
  ion-segment:not([color]) {
    ion-segment-button {
      --color-checked: var(--is-segment-checked-color);
    }
  }
  .theme-light ion-menu ion-list ion-item-divider:not([color]) {
    background-color: var(--ion-color-is-mute-light-tint);
    color: var(--ion-color-is-mute-light-contrast);
  }
  ion-searchbar {
    --color: var(--ion-color-is-text-dark-theme);
  }
  ion-header ion-toolbar ion-title {
    font-weight: 400;
  }
}
